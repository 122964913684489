<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable" width="70%">
		<div v-loading="loading" class="dlalog-body">
			<div>
				<el-table ref="table" :data="tableData" border :header-cell-style="{'background-color':'#f9fafc'}"
					max-height="700" row-key="id" :tree-props="{ children: 'measList'}" @row-click="expandChange"
					style="margin-bottom: 20px;">
					<el-table-column prop="unitName" align="center" label="单体设备" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="unitMeasName" align="center" label="测点" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="alarm" align="center" label="报警" show-overflow-tooltip width="150">
						<template slot-scope="scope" v-if="!scope.row.measList">
							<el-select v-model="scope.row.alarm" :disabled="dialogObj.type==0" v-if="scope.row.type==0">
								<el-option label="正常" :value="0"></el-option>
								<el-option label="一级报警" :value="1"></el-option>
								<el-option label="二级报警" :value="2"></el-option>
								<el-option label="三级报警" :value="3"></el-option>
							</el-select>
							<el-select v-model="scope.row.alarm" :disabled="dialogObj.type==0" v-if="scope.row.type==2">
								<el-option label="正常" :value="0"></el-option>
								<el-option label="报警" :value="1"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column prop="alarmValue" align="center" label="报警值" show-overflow-tooltip width="150">
						<template slot-scope="scope" v-if="!scope.row.measList">
							<el-input v-model="scope.row.alarmValue" :disabled="dialogObj.type==0"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="handleStatus" align="center" label="处理情况" show-overflow-tooltip width="150">
						<template slot-scope="scope" v-if="scope.row.measList">
							<el-select v-model="scope.row.handleStatus" :disabled="dialogObj.type==0">
								<el-option label="不用处理" :value="0"></el-option>
								<el-option label="未处理" :value="1"></el-option>
								<el-option label="已处理" :value="2"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip width="400">
						<template slot-scope="scope" v-if="!scope.row.measList">
							<el-input type="text" v-model="scope.row.remarks" :disabled="dialogObj.type==0">
							</el-input>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()" v-if="dialogObj.type != 0">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				tableData: []
			};
		},
		props: {},
		watch: {},
		methods: {
			//点击某一行的回调事件
			expandChange(row, index, e) {
				if (index.label == '单体设备') {
					this.$refs.table.toggleRowExpansion(row)
				}
			},
			//打开弹窗
			open() {
				this.tableData = [];
				this.get();
			},
			//获取数据
			get() {
				if (this.dialogObj.type == 0) {
					this.title = "查看";
				} else {
					this.title = "编辑";
				}
				this.loading = true;
				this.$get("/backend-api/data/alarm/detail", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.tableData = res.data
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			//提交表单
			submit() {
				this.loading = true;
				if (this.dialogObj.type == 1) {
					this.$postData("/backend-api/data/alarm/update", this.tableData).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				}
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
