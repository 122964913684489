<template>
	<a class="text-center bottom-text" v-if="isShow" href="https://beian.miit.gov.cn" target="_blank">
		© 2021 smheee.com 长材智能运维-圣名远扬, All Rights Reserved.Email : shengming_tech@163.com Tel: 020-31802914 粤ICP备2021034595号-1 
	</a>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				isShow: true
			}
		},
		watch: {
			"$route.name": {
				handler(val) {
					if (val == 'expert' || val == 'supplier' || val ==
						'companyJkdp'|| val == 'companyNangangJkdp'|| val == 'companySanhezhonggongJkdp') {
						this.isShow = false;
					} else {
						this.isShow = true;
					}
				},
				immediate: true,
			},
		},
		methods: {},
	};
</script>
<style lang='scss'>
</style>
